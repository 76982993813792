<template>
    <div>
        <innerheader />
        <Policydetails />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import Policydetails from "@/components/policydetails/policy-details.vue";

export default {
  name: 'policydetails',
  components: {
     innerheader,
     Policydetails
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/policy-qoute.scss';
</style>