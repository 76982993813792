<template>
  <div>
    <div class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <span>Your proposal is submitted successfully to insurance company</span><br>
            <span>HDFC LIFE will send email to the customer's registered email Id - {{ this.maskid }} and SMS to Mobile number {{this.hiddennum}}.</span>
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "paymnt",
  props: {  },
  components: {},
  data() {
    return {
      userData:{},
      email:"",
      mobile:"",
      maskid:"",
      hiddennum:"",
    };
  },
  mounted() {
    
    // this.paymentResponse();
    //this.getPayment()
 
    this.userData = JSON.parse(sessionStorage.getItem("filledData"));
    this.email = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["email"]["input"]
    this.mobile = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["mobileNumber"]["input"]
    console.log("emailmob",this.email,this.mobile)



    console.log("data",this.userData)
    this.maskid = this.email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c);
     this.hiddennum= this.mobile.slice(0, 2) + this.mobile.slice(2).replace(/.(?=...)/g, '*');

  
  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>