<template>
    <div>
      <div class="container">
          <div class="success-img-holder text-center mt-5 mb-3">
            <br/>
            <br/>
            <br/>
            <figure>
              <img  src="~@/assets/images/payment_scuccessful.png" alt="" />
            </figure>
            <br/>
              <figcaption class="mb-4">
              <h1>Thank You</h1><br>
              <span>In case of any further query, your RM will get in touch with you.</span>
            </figcaption>
          </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    name: "docsubmitted",
    props: {  },
    components: {},
    data() {
      return {
      };
    },
    mounted() {
    
    },
    methods: {
      
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "~@/assets/scss/components/paymentsuccess.scss";
  // @import "~@/assets/scss/components/paymentfailed.scss";
  .thankyou{
    font-size: xx-large;
    font-weight:bold;
    color: $main-bg;
  }
  </style>