<template>
  <div class="loading_block text-center">
    <div>
      <figure>
        <img src="~@/assets/images/loading_img.png" alt="" />
      </figure>
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <p>Preparing to collect your details..</p>
            <b>Buy Now &amp; Save more!</b>
          </div>
          <div class="carousel-item">
            <p>Fetching Quote From A Company Having The Best Claim Ratio..</p>
            <b>Buy Now &amp; Save More!</b>
          </div>
          <div class="carousel-item">
            <p>
              Fetching Quote From Top Insurance Companies In The Life Term Segment...
            </p>
            <b>Buy Now &amp; Save More!</b>
          </div>
        </div>
      </div>
      <div>
        <img src="~@/assets/images/loading_gif.gif" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "single-loading",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/assets/scss/components/loading.scss';
</style>
