<template>
    <div class="check-out">
        <innerheader />
        <Profile @myVarChanged="updateMyVar" @myVarChanged2="updateMyVar2" />
        <checkout :userProfileData="userData" :userProfileData2="user2Data" />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import checkout from "@/components/checkout/checkout.vue";
import Profile from "@/components/profile/profile.vue";

export default {
  name: 'policyquote',
  components: {
     innerheader,
     Profile,
     checkout
  }, 
  data() {
    return {
      userData: "",
      user2Data: "",
    }
  },
  methods: {
    updateMyVar(val){
      console.log('update my vae called');
      this.userData = val;
    },
    updateMyVar2(val){
      console.log('update my vae2 called');
      this.user2Data = val;
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
</style>