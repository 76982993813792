<template>
  <div>
    <div class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <h1>Thank You</h1><br>
            <div v-if="this.proposalStatus">
            <span >{{this.proposalStatus}}.</span><br><br>
            </div>
            <div v-else>
            <span>An email has been sent successfully to the customer’s registered email Id - {{ this.maskid }} and SMS on Mobile number {{this.hiddennum}}. </span><br><br>
            <span>Customer has to review and submit the proposal along with making a payment.</span>
            </div>
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "paymnt",
  props: {  },
  components: {},
  data() {
    return {
      proposalStatus:"",
      userData:{},
      email:"",
      mobile:"",
      maskid:"",
      hiddennum:"",
    };
  },
  mounted() {
    // this.paymentResponse();
    //this.getPayment()
    let s=this.$route.query.msg
    this.proposalStatus=s
    this.userData = JSON.parse(sessionStorage.getItem("filledData"));
    this.email = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["email"]["input"]
    this.mobile = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["mobileNumber"]["input"]
    console.log("emailmob",this.email,this.mobile)



    console.log("data",this.userData)
    this.maskid = this.email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c);
     this.hiddennum= this.mobile.slice(0, 2) + this.mobile.slice(2).replace(/.(?=...)/g, '*');

  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>