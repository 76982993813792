<template>
    <div>
        <innerheader />
        <Policyquote :userProfileData="userData" :userProfileData2="user2Data" @myVarChanged3="updateMyVar3"/>
        <Profile @myVarChanged="updateMyVar" @myVarChanged2="updateMyVar2" :userProfileData3="user2Data2"/>
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import Policyquote from "@/components/policyqoute/policy-quote.vue";
import Profile from "@/components/profile/profile.vue";

export default {
  name: 'policyquote',
  components: {
     innerheader,
     Profile,
     Policyquote,
  },
  data() {
    return {
      userData: "",
      user2Data: "",
      user2Data2: "",
    }
  },
  methods: {
    updateMyVar(val){
      console.log('update my var called');
      this.userData = val;
    },
    updateMyVar2(val){
      console.log('update my var2 called');
      this.user2Data = val;
    },
    updateMyVar3(val) {
      console.log('Update my var3 called');
      this.user2Data2 = val;
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/policy-qoute.scss';
</style>