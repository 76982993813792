<template>
    <div>
        <div class="inner-header-bg">
            <innerheader />
        </div>
        <termplanqte />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import termplanqte from "@/components/termplanqte/termplanqte.vue";

export default {
    name:' term-plan-qte',
    components: {
        innerheader,
        termplanqte
    },
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/term-plan-qte.scss';
</style>