<template>
    <div>
        <innerheader />
        <uploaddocument />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import uploaddocument from "@/components/uploaddocument/upload-doc.vue";

export default {
  name:' uploaddoc',
  components: {
     innerheader,
     uploaddocument
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/uploaddoc.scss';
</style>