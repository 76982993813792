<template>
    <div>
        <innerheader />
        <thankyoureject />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import thankyoureject from "@/components/payment/thankyou.vue";

export default {
  name: 'thankyourejectdiy',
  components: {
     innerheader,
     thankyoureject
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style>