<template>
  <div class="inner-header d-flex justify-content-between align-items-center">
    <div class="brand-logo">
      <a href=""><img src="~@/assets/images/logo.png" alt="" /></a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <router-link to="/dashboard" class="dashbrd d-flex justify-content-center align-items-center">
        <img src="~@/assets/images/dashboard-icon.svg" alt="" class="dashboard-img d-md-none"/> 
        <span class="d-none d-md-block">Dashboard</span>        
      </router-link>
      <div class="dropdown header-profile dropdown-menu-right">
        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="~@/assets/images/user-dropdown.png" alt="">
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul class="mb-0">
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon1.png" alt=""/> {{ rmData.Name }}
            </li>
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon2.png" alt=""/> {{ rmData.Mobile }}
            </li>
            <li class="align-items-center d-flex"> 
              <img src="~@/assets/images/icon3.png" alt=""/> {{ rmData.Email }}
            </li>
            <li class="align-items-center d-flex">
              <img src="~@/assets/images/left-arrow-grey.png" alt=""/> 
              <span class="logout cursor-pointer" @click="logout()">Logout</span> 
              <div class="loading_block_outer" v-if="loading">
                <Loading3 msg='Logging out'/>
              </div> 
            </li>
          </ul>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import $ from "jquery";
import {getLogoutURL} from '@/mixins/common';
import Loading3 from "@/components/loading3.vue";

export default {
  name: "innerheader",
  emits: ["clicked-rm-details"],
  props: {
    msg: String,
  },
  components: {
    Loading3    
  },
  mixins:[getLogoutURL],

  mounted() {

    let rmId = this.$route.query.id;
    if (sessionStorage.getItem("rm_data")) 
    {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
    }
    else
    {
      let qData = [rmId];
      let headers = {};
      if(rmId) {
        this.backendApi("getRmDetails", qData, headers, "get")
        .then((response) => {
          //console.log('save quote response: ', response);
          if(response == 'error') {
            //self.apiError = true;
            
            // alert('RM data not fetched, please refresh the page');
          } else if(response && response.data) {
            this.rmData = response.data[0];
            //console.log('rmData: 4: ', this.rmData);
            if(this.rmData != undefined)
            {
              sessionStorage.setItem("rm_data", JSON.stringify(this.rmData));
              localStorage.setItem("rm_data", JSON.stringify(this.rmData));
            }
            this.$emit('clicked-rm-details', this.rmData);
            // this.getToken();
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });

      }
    
    }
    
  },
  data() {
    return {
      rmData: "",
      loading: false,
    };
  },
  methods: {
    getToken() {
      this.backendApi("getToken")
      .then((response) => {
        //console.log('save quote response: ', response);
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data){
          //console.log('response token: ', response.data)

          let token = response.data.Data.Token;
          let USERID = response.data.Data.UserID;
          let key = this.rmData.Userid;

          let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
          if(spdetail_flag == 0)
          {
            this.getSPDetails(token, USERID, key);
          }
        }

        
      })
      .catch((error) => {
        console.log('error msg block get token', error);
        let tmpData = {
            "advisorCode": "00050007",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999 (Dummy code)",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01370250",
            "spCode": "93432",
            "bankBrnch": "BJ00",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
      });
    },
    getSPDetails(token, USERID, key) {
      let qData = {
        "data":{
          "UserID": USERID, 
          "Token": token,  
          "Key": key 
        }
      }
      this.backendApi("getSPDetails", qData)
      .then((response) => {
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          //console.log('response sp details: ', response.data)
          // sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          // localStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          let tmpData = {
            "advisorCode": "00050007",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01370250",
            "spCode": "93432",
            "bankBrnch": "BJ00",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
        }
      })
      .catch((error) => {
        console.log('error msg block sp', error);
      });
    },
    logout(){
      sessionStorage.removeItem("rm_data");
      localStorage.removeItem("rm_data");
      console.log("logged out");
      if (sessionStorage.getItem("rm_data")){
        this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
      }
      else
        this.rmData = "";
      let location = getLogoutURL();
      this.loading = true;
      window.location.replace(location);
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/inner-header.scss";
</style>