<template>
    <div>
        <innerheader />
        <payment />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerHeaderDIY.vue";
import payment from "@/components/payment/payment.vue";

export default {
  name: 'paymnt',
  components: {
     innerheader,
     payment
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style>