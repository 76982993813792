<template>
    <div>
        <innerheader />
        <proposaladdresscheck />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerHeaderDIY.vue";
import proposaladdresscheck from "@/components/proposal/proposal-details-check-diy.vue";

export default {
  name:' proposaldetailscheckdiy',
  components: {
     innerheader,
     proposaladdresscheck
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
@import '~@/assets/scss/components/proposal-page.scss';
</style>